<template>
  <b-row v-if="myCategories" class="mt-5">
    <b-col cols="12">
        <h3 class="mb-3">{{ $t('general.categories')}}</h3>
        <p>
          <span v-for="cat in myCategories" :key="cat.id">
            <span v-for="c in cat.names" :key="c.id">
              <template v-if="c.language.IANA == $i18n.locale">
               <b-button class="mr-3 mb-3" pill size="md" variant="outline-secondary" :to="{ name: 'CategoryMedia', params: {lang: c.language.shortName, categoryId: c.id} }">{{ c.name }}</b-button>
              </template>
            </span>
          </span>
        </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CategoriesSection",

  props: { 
    categories: Array
  },
  computed: {
    myCategories: function() {
      return this.categories;
    }
  },
  data: function() {
    return {

    }
  }
}
</script>

<style></style>
